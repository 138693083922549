import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { HandbookRegionEnum } from '@src/components/sections/Internal/HandbookRegions/region';
import { Handbook } from '@src/@types/models';
import { UserEntity } from '@src/entities';
import { USER_ACCOUNT_TYPE_FRAGMENT } from './useUserAccountType';
import useApolloEntity from './useApolloEntity';

interface ICSPDirectives {
  [key: string]: string[];
}

interface CreativeInput {
  totalCopy: number;
  totalDesign: number;
  totalPolicyWriting: number;
}
export interface Organization {
  id: number;
  uuid: string;
  name: string;
  industryType: string;
  employeeRange: any; // change this to enum or something else
  createdAt: Date;
  isUsedCustomDomain: boolean;
  customDomain: string;
  slug: string;
  isCustomDomainWorking: boolean;
  isEmployeeWelcomeEmailEnabled: boolean;
  isDefaultEmployeeWelcomeTitle: boolean;
  isDefaultEmployeeWelcomeMessage: boolean;
  employeeWelcomeTitle: string;
  employeeWelcomeMessage: string;
  largeIcon: string;
  icon: string;
  customHeaderEmail: string;
  isLaunched: boolean;
  launchedDate: string | null;
  requiresSignatureField: boolean;
  useDobPassword: boolean;
  isSignatureBannerTop: boolean;
  customSignBanner: string;
  isEmployeeWelcomeEmailEnabledByManualRegistation: boolean;
  isSSOForced: boolean;
  isActive: boolean;
  enableAutoPolicy: boolean;
  purchasedAutomatedPolicyRegions: Array<keyof typeof HandbookRegionEnum>;
  cspDirectives: ICSPDirectives | null;
  handbooks: Handbook[];
  isConnectedToHRISOrSFTPOrSSO: boolean;
  creative: CreativeInput;
  location: string;
  organizationUserRelations: any;
  loginMethod: string;
  crawlable: boolean;
  printable: boolean;
  shownFooterBannerToHandbooks: boolean;
  saleClosedBy: UserEntity | null;
  useSourceIdLastNamePassword: boolean;
  enableAnalytics: boolean;
  isWizardEnabled: boolean;
  enablePreregistration: boolean;
  enableReport: boolean;
  isUsedCustomEmailHeader: boolean;
  isJustInTimeProvisioningEnabled: boolean;
  featureFlags: string[];
  enableChecklistTracking: boolean;
}

export const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationFragment on Organization {
    id
    uuid
    name
    industryType
    employeeRange
    createdAt
    isUsedCustomDomain
    customDomain
    slug
    isCustomDomainWorking
    isEmployeeWelcomeEmailEnabled
    isDefaultEmployeeWelcomeTitle
    isDefaultEmployeeWelcomeMessage
    employeeWelcomeTitle
    employeeWelcomeMessage
    isDefaultEmployeeWelcomeWithLinkTitle
    isDefaultEmployeeWelcomeWithLinkMessage
    employeeWelcomeWithLinkTitle
    employeeWelcomeWithLinkMessage
    isEmployeeWelcomeEmailEnabledByManualRegistation
    isSSOForced
    isActive
    useDobPassword
    useSourceIdLastNamePassword
    icon
    largeIcon
    location
    enableAutoPolicy
    isShownAPUEnabled
    isConnectedToHRISOrSFTPOrSSO
    enablePreregistration
    enableAnalytics
    isWizardEnabled
    enableReport
    website
    isSentVerifyCustomDomain
    verifiedCustomDomainDate
    customHeaderEmail
    isUsedCustomEmailHeader
    isJustInTimeProvisioningEnabled
    featureFlags
    adminLoginMethods
    inprogressHandbook
    fromEmail {
      id
      email
    }
    enableChecklistTracking
  }
`;

export const MY_ORGANIZATIONS = gql`
  query GetOrganizations {
    getMyOrganizations {
      ...OrganizationFragment
      organizationUserRelations {
        id
        userAccountType {
          ...UserAccountTypeFragment
        }
      }
    }
  }
  ${ORGANIZATION_FRAGMENT}
  ${USER_ACCOUNT_TYPE_FRAGMENT}
`;

export const GET_SELECTED_ORGANIZATION = gql`
  query GetSelectedOrganization {
    selectedOrganization @client {
      ...OrganizationFragment
      userAccountType {
        ...UserAccountTypeFragment
      }
    }
  }
  ${ORGANIZATION_FRAGMENT}
  ${USER_ACCOUNT_TYPE_FRAGMENT}
`;

export const UPDATE_ORGANIZATION_DETAILS = gql`
  mutation UpdateOrganization($organizationId: Float!, $data: UpdateOrganizationDetailsInput!) {
    updateOrganizationDetails(organizationId: $organizationId, data: $data) {
      id
    }
  }
`;

export const UPDATE_ORGANIZATION_CONFIGURATION = gql`
  mutation UpdateOrganization(
    $organizationId: Float!
    $data: UpdateOrganizationConfigurationInput!
  ) {
    updateOrganizationConfiguration(organizationId: $organizationId, data: $data) {
      id
    }
  }
`;

const GET_MY_ORGANIZATION_BY_SLUG_OR_CUSTOM_DOMAIN = gql`
  query getMyOrganizationBySlugOrCustomDomain($url: String!) {
    getMyOrganizationBySlugOrCustomDomain(url: $url) {
      id
      slug
      name
    }
  }
`;

export const UPDATE_WELCOME_EMPLOYEE_SETTING = gql`
  mutation UpdateWelcomeEmployeeSetting(
    $organizationId: Float!
    $data: UpdateWelcomeEmployeeSettingInput!
  ) {
    updateWelcomeEmployeeSetting(organizationId: $organizationId, data: $data) {
      id
    }
  }
`;

export const GET_CURRENT_ORGANIZATION = gql`
  query GetSelectedOrganization {
    getSelectedOrganization {
      ...OrganizationFragment
      employeeDashboardCustomization {
        headerTextFont
        customSSOMessagedFont
        handbookNameFont
        headerTitle
        loginButtonLabel
        isCustomLoginMessage
        customLoginMessage
        loginByEmailPassword
        includeLogoInLogin
      }
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const GET_ORGANIZATION_COUNTER = gql`
  query GetOrganizationCounter {
    getOrganizationCounter {
      collaboratorCount
      employeeCount
      groupCount
    }
  }
`;
export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($organizationId: Float!, $data: UpdateOrganizationInput!) {
    updateOrganization(organizationId: $organizationId, data: $data) {
      id
      name
      isUsedCustomDomain
      customDomain
      industryType
      employeeRange
      slug
      isSSOForced
    }
  }
`;

export const GET_ORGANIZATION_SFTP = gql`
  query GetOrganizationSFTP($organizationId: Float!) {
    getOrganizationSFTP(organizationId: $organizationId) {
      id
      isActive
    }
  }
`;

export const UPDATE_CSP = gql`
  mutation UpdateOrganizationCSPDirective(
    $organizationId: Float!
    $data: UpdateOrganizationCSPDirectivesInput!
  ) {
    updateOrganizationCSPDirective(organizationId: $organizationId, data: $data) {
      id
    }
  }
`;

export const GET_ALL_ORGANIZATIONS_IN_LOOKUP = gql`
  query GetOrgsInLookup($data: GetOrgsInLookupInput!) {
    getOrgsInLookup(data: $data) {
      organizations {
        id
        name
      }
      next
    }
  }
`;

export const UPDATE_EMPLOYEE_PORTAL = gql`
  mutation UpdateEmployeePortal($organizationId: Float!, $data: UpdateEmployeePortalInput!) {
    updateEmployeePortal(organizationId: $organizationId, data: $data) {
      id
    }
  }
`;

export const REQUEST_TEAM_HELP = gql`
  mutation RequestTeamHelp($data: RequestTeamHelpInput!, $organizationId: Float!) {
    requestTeamHelp(data: $data, organizationId: $organizationId)
  }
`;

export const CANCEL_REQUEST = gql`
  mutation CancelRequestToVerifyCustomDomain($organizationId: Float!) {
    cancelRequestToVerifyCustomDomain(organizationId: $organizationId)
  }
`;

export const ROOT_VERIFY_CUSTOM_DOMAIN = gql`
  mutation VerifyCustomDomain($organizationId: Float!) {
    verifyCustomDomain(organizationId: $organizationId) {
      id
    }
  }
`;

export const TOGGLE_USED_CUSTOM_DOMAIN = gql`
  mutation ToggleUsedCustomDomain($organizationId: Float!, $isUsedCustomDomain: Boolean!) {
    toggleUsedCustomDomain(organizationId: $organizationId, isUsedCustomDomain: $isUsedCustomDomain)
  }
`;

export const RESTORE_ACCOUNT_EMPLOYEE_PORTAL = gql`
  mutation RestoreAccountDefault($organizationId: Float!) {
    restoreAccountDefault(organizationId: $organizationId) {
      ...OrganizationFragment
      employeeDashboardCustomization {
        headerTextFont
        customSSOMessagedFont
        handbookNameFont
        headerTitle
        loginButtonLabel
        isCustomLoginMessage
        customLoginMessage
        loginByEmailPassword
        includeLogoInLogin
      }
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

const useOrganization = () => {
  const refetchQueriesAfterUpdate = [
    {
      query: GET_CURRENT_ORGANIZATION,
    },
  ];

  const Organization = useApolloEntity<{
    get: any;
    getMyOrganizationBySlugOrCustomDomain: any;
  }>({
    useLazyQuery: {
      getMyOrganizations: {
        query: MY_ORGANIZATIONS,
        extractEntity(data: any): Organization[] {
          return data && data.getMyOrganizations;
        },
      },
      getMyOrganizationBySlugOrCustomDomain: {
        query: GET_MY_ORGANIZATION_BY_SLUG_OR_CUSTOM_DOMAIN,
        extractEntity(data: any): Organization {
          return data && data.getMyOrganizationBySlugOrCustomDomain;
        },
      },
      getSelectedOrganization: {
        query: GET_CURRENT_ORGANIZATION,
        extractEntity(data: any): Organization {
          return data && data.getSelectedOrganization;
        },
      },
    },
    useMutation: {
      updateWelcomeEmployeeSetting: {
        mutation: UPDATE_WELCOME_EMPLOYEE_SETTING,
        refetchQueries: refetchQueriesAfterUpdate,
      },
    },
  });

  return Organization;
};

function getSelectedOrganization(): any {
  try {
    const { data } = useQuery(GET_SELECTED_ORGANIZATION);
    return data.selectedOrganization || null;
  } catch (e: any) {
    return null;
  }
}

export const OrganizationClient = {
  getSelectedOrganizationId: () => {
    const selectedOrganization = getSelectedOrganization();
    return selectedOrganization && selectedOrganization.id;
  },
  getSelectedOrganization,
};

export default useOrganization;
