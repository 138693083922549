const {
  colors,
  borderColor,
  maxWidth,
  zIndex,
  textAlign,
} = require("tailwindcss/defaultTheme")
const transitionsPlugin = require("tailwindcss-transitions")

module.exports = {
  content: [
    "./src/pages/**/*.{js,jsx,ts,tsx}",
    "./src/components/**/*.{js,jsx,ts,tsx}",
  ],
  theme: {
    screens: {
      sm: "640px",
      md: "768px",
      lg: "920px",
      xl: "920px",
    },


    extend: {
      fontFamily: {
        sans: [
          "Lato",
          "system-ui",
          "BlinkMacSystemFont",
          "-apple-system",
          "Segoe UI",
          "Roboto",
          "Oxygen",
          "Ubuntu",
          "Cantarell",
          "Fira Sans",
          "Droid Sans",
          "Helvetica Neue",
          "sans-serif",
        ],
        serif: [
          "Constantia",
          "Lucida Bright",
          "Lucidabright",
          "Lucida Serif",
          "Lucida",
          "DejaVu Serif",
          "Bitstream Vera Serif",
          "Liberation Serif",
          "Georgia",
          "serif",
        ],
        mono: [
          "Menlo",
          "Monaco",
          "Consolas",
          "Liberation Mono",
          "Courier New",
          "monospace",
        ],
        avenir: ["Avenir"],
        avenirBook: ["AvenirBook"],
        avenirRoman: ["AvenirRoman"],
        avenirHeavy: ["AvenirHeavy"],
        avenirMedium: ["AvenirMedium"],
        circularStdBook: ["CircularStdBook"],
        circularStdMedium: ["CircularStdMedium"],
        latoRegular: ["LatoRegular"],
        latoItalic: ["LatoItalic"],
        latoBold: ["LatoBold"],
        nunitoSans: ["NunitoSans"],
        nunitoSansBold: ["NunitoSansBold"],
        avenirBookOblique: ["AvenirBookOblique"],
        avenirMediumOblique: ["AvenirMediumOblique"],
        avenirHeavyOblique: ["AvenirHeavyOblique"],
      },

      backgroundSize: {
        min: "37.5%",
      },

      fontSize: {
        "overview-hanbook-title": "1.375rem",
        "overview-hanbook-note": "0.8125rem",
        "3.25": "0.8125rem",
        "3.75": "0.9375rem",
        "4": "1rem",
        "4.25": "1.0625rem",
        "5": "1.25rem",
        "7.5": "1.875rem",
        "8": "2rem",
        "signup-form": "0.625rem",
        "10/16": "0.625rem",
        "label-form": "0.675rem",
        "13/16": "0.8125rem",
        "14/16": "0.875rem",
        "15/16": "0.9375rem",
        "17/16": "1.0625rem",
        "21/16": "1.3125rem",
        "19/16": "1.1875rem",
        24: "6rem",
      },

      letterSpacing: {
        "1/2": "0.125rem",
      },

      opacity: {
        "0": "0",
        "10": ".1",
        "15": ".15",
        "20": ".2",
        "30": ".3",
        "34": ".34",
        "36": ".36",
        "40": ".4",
        "48": ".48",
        "50": ".5",
        "60": ".6",
        "64": ".64",
        "65": ".65",
        "70": ".7",
        "75": ".75",
        "80": ".8",
        "90": ".9",
        "100": "1",
      },
      borderWidth: {
        default: "1px",
        "0": "0",
        "3/2": "1.5px",
        "2": "2px",
        "4": "4px",
      },
      inset: {
        "1": "0.25rem",
        "3": "0.75rem",
        "4": "1rem",
        "5": "1.25rem",
        "7": "1.75rem",
        "7.5": "1.875rem",
        "[40%]": "40%",
        "1/2": "50%",
        "90": "90%",
        "9": "2.25rem",
        "10": "2.5rem",
        "-5": "-1.25rem",
        "-45": "-11.25rem",
        "-52": "-13rem",
        "-144": "-36rem",
        "12": "3rem",
        "28": "7rem",
        "30/16": "1.875rem",
        "18.252": "4.563rem",
      },
      boxShadow: {
        close: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        "5": "0 2px 8px 0 rgba(0,0,0,0.5)",
        "7": "0 2px 4px 0 rgba(0,0,0,0.07)",
        "12": "0 2px 4px 0 rgba(0,0,0,0.12)",
        "16": "0 2px 4px 0 rgba(0,0,0,0.16)",
        "19": "0 2px 8px 0 rgba(0, 0, 0, 0.19)",
        "setting-dropdown": "0 2px 4px rgba(0, 0, 0, 0.14)",
        "20": "0 2px 4px 0 rgba(0,0,0,0.5)",
        "21": "0 12px 14px 0 rgba(0,0,0,0.15)",
        "22": "0 0px 4px rgba(0,0,0,0.5)",
        "submit-form-button": "0 0px 4px 0 rgba(0,0,0,0.12)",
        "login-form": "0 2px 70px 0 rgba(4,2,33,0.13)",
      },
      height: {
        "fit-content": "fit-content",
        "fill-available": "fill-available",
        "1/2": "50%",
        "1/3": "33.333333%",
        "2/3": "66.666667%",
        "1/4": "25%",
        "2/4": "50%",
        "3/4": "75%",
        "1/5": "20%",
        "2/5": "40%",
        "3/5": "60%",
        "4/5": "80%",
        "1/6": "16.666667%",
        "2/6": "33.333333%",
        "3/6": "50%",
        "4/6": "66.666667%",
        "5/6": "83.333333%",
        "5/7": "57%",
        "1/12": "8.333333%",
        "2/12": "16.666667%",
        "3/12": "25%",
        "4/12": "33.333333%",
        "5/12": "41.666667%",
        "6/12": "50%",
        "7/12": "58.333333%",
        "8/12": "66.666667%",
        "9/12": "75%",
        "10/12": "83.333333%",
        "11/12": "91.666667%",
        "11/20": "55%",
        "2.5": "0.625rem",
        "4": "1rem",
        "5": "1.25rem",
        "6": "1.5rem",
        "7": "1.75rem",
        "7.5": "1.875rem",
        "37/16": "2.3125rem",
        "9": "2.25rem",
        "10": " 2.5rem",
        "10.5": "2.625rem",
        "11": "2.75rem",
        "35/16": "2.1875rem",
        "11.5": "2.875rem",
        "13": "3.25rem",
        "14": "3.5rem",
        "15": "3.75rem",
        "18": "4.5rem",
        "20": "5rem",
        "22": "5.5rem",
        "25": "6.25rem",
        "36": "9rem",
        "60": "15rem",
        "68": "17rem",
        "72": "18rem",
        "95": "95%",
        "84": "21rem",
        "87.5": "21.875rem",
        "92": "23rem",
        "100": "25rem",
        "125": "31rem",
        "170": "42.5rem",
        "add-collaborator": "1.1875rem",
        "280": "70rem",
        "312": "78rem",
        "60vh": "60vh",
        "65vh": "65vh",
        "70vh": "70vh",
        "75vh": "75vh",
        "80vh": "80vh",
        "76": "19rem",
        "112": "28.125rem",
        "124": "31rem",
        "140": "35rem",
      },
      width: {
        "2.5": "0.625rem",
        "4": "1rem",
        "7": "1.75rem",
        "7.5": "1.875rem",
        "8": "2rem",
        "9": "2.25rem",
        "37/16": "2.3125rem",
        "11": "2.75rem",
        "11.5": "2.875rem",
        "13": "3.25rem",
        "18": "4.5rem",
        "24": "6rem",
        "25": "6.25rem",
        "34": "8.5rem",
        "62": "15.5rem",
        "64": "16rem",
        "70": "17.5rem",
        "75": "18.75rem",
        "30": "7.5rem",
        "32": "8rem",
        "36": "9rem",
        "40": "10rem",
        "50": "12.5rem",
        "54": "13rem",
        "56": "14rem",
        "68": "17rem",
        "76": "19rem",
        "82": "20.5rem",
        "87": "21.75rem",
        "90": "22.5rem",
        "90.5": "22.625rem",
        "97": "24.25rem",
        "100": "25rem",
        "120": "30rem",
        "152": "38rem",
        "160": "40rem",
        "164": "41rem",
        "176": "44rem",
        "232": "58rem",
        "40%": "40%",
        "2/5": "45%",
        "27/16": "1.6875rem",
        "csp-item": "calc((100% / 3) - 30px)",
        "integration-item": "calc(100% / 2 - 5px)",
        "header-login-desktop": "calc(100% - 7rem)",
        "header-login-mobile": "calc(100% - 1rem)",
        "275": "68rem",
        "260": "65rem",
        "27": "7rem",
        "28": "7.5rem",
        "106": "26.6rem",
        "145": "36rem",
        "150": "37.5rem",
        "94": "24.45rem",
        "104": "26rem",
        "125": "31.25rem",
        "133": "33.25rem",
        "51.5": "12.875rem",
        "111.752": "27.938rem",
        "2.5/5": "48%",
        "52": "13.2rem",
        "140": "35rem",
      },

      borderRadius: {
        none: "0",
        xl: "1.5rem",
        md: "0.375rem",
        "4": "1rem",
        "5": "1.25rem",
        "1/2": "50%",
        "3": "0.75rem",
        "20": "5rem",
        "13": "13rem",
        "8px": "8px",
      },
      lineHeight: {
        "0": 0,
        sm: "0.875rem",
        "3.25": "0.8125rem",
        "4": "1rem",
        "4.752": "1.188rem",
        "8": "2rem",
        "5": "1.25rem",
        "7": "1.75rem",
        "7.5": "1.875rem",
        "10": "2.5rem",
        "19/16": "1.1875rem",
        "welcome-body": "1.687rem",
        "welcome-title": "1.875rem",
        "overview-handbook-note": "1.125rem",
        "delete-employee-modal": "1.375rem",
        "create-group-modal": "2.0625rem",
        "add-employee-form-list": "1.375rem",
        "add-employee-form-list-note": "1.1875rem",
        "import-body": "1.5625rem",
        "21/16": "1.3125rem",
        "7.252": "1.813rem",
      },

      colors: {
        blue: {
          ...colors.blue,
          default: "#1283FF",
          "0.18": "rgba(71,166,248,0.18)",
          "1": "#F1F5F5",
          "2": "#95CCD6",
          "3": "#39A1B2",
          "4": "#DEF0FF",
          "5": "#47A6F8",
          "6": "#EFF7FF",
          "7": "#C5DDF1",
          "8": "#41A4FB",
          "9": "rgba(178, 215, 255, 0.2)",
          "10": "#01A7FF",
          "11": "#3d4353",
          "12": "#8db8de",
          "13": "#47a6f7",
          "14": "#3B88FD",
          "15": "#b2d7ff",
          "16": "#5DB0F8",
          "17": "#24bdff",
          "18": "#44898B",
          "19": "#41898C",
          "20": "#E0EFFF",
          "21": "#C2EEF0",
          "22": "#0095FF",
          "23": "#0898FF",
          "24": "#0496FF",
          "25": "#1084FF",
          "26": "#00A9FF",
        },
        coral: {
          "1": "#FF7470",
          "2": "#fcefee",
          "3": "#e68983",
          "4": "#CDE5E7",
        },
        gray: {
          ...colors.gray,
          default: "#8C9BB3",
          "1": "#99ACAE",
          "2": "#537175",
          "3": "#0E282D",
          "4": "#8FA2A5",
          "5": "#E9EFF0",
          "6": "#D7D7D7",
          "7": "#FCFCFC",
          "8": "##A0B1B3",
          "9": "#E9EFF0",
          "10": "#979797",
          "11": "rgba(0,0,0,0.23)",
          "12": "#DAE4ED",
          "13": "#8C93A6",
          "14": "#81858F",
          "15": "#999FAD",
          "16": "#D1D1D1",
          "17": "#8C96A0",
          "18": "#999FAE",
          "19": "#9BA1AE",
          "20": "#D9DCE3",
          "21": "#F7F7F7",
          "22": "#eef0f5",
          "23": "#f4f4f6",
          "24": "#d8d8d8",
          "25": "#3E505E",
          "26": "#464b5b", // new gray for parapraph
          "27": "#82899a",
          "28": "#5F6470", // new for search
          "29": "#474C5C",
          "30": "#EAEAEA",
          "31": "#F1F1F3",
          "32": "#e2e8f0",
          "33": "#A9B1B9",
          "34": "#f6f7fa",
          "35": "#EEF0F5",
          "36": "#CACCD0",
          "37": "#A9B1B9",
          "38": "#F4F4F4",
          "39": "#747D86",
          "40": "#8D96A0",
          "41": "#9DA0A8",
          "42": "#F7F8FB",
          "43": "#2E4253",
          "44": "#4C5261",
          "45": "#F9F9F9",
          "46": "#F5F8FB",
          "47": "#FAF9F9",
          "48": "#C1C1C1",
          "49": "#E2E2E2",
          "50": "#D9D9D9",
          "51": "#C9C9C9",
          "52": "#dfe2e7",
          "53": "#ACACAC",
          "54": "#7C7C7C",
          "55": "#F0F0F0",
          "56": "#D2D2D2",
          "57": "#F1F1F1",
          "58": "#A3A3AB",
        },
        teal: {
          ...colors.teal,
          "1": "#6CB3C4",
          "2": "#3F9EA8",
          "3": "#146368",
          "4": "#39A1B2",
          "5": "#3CA2B3",
          "6": "#9CD6D9",
          "7": "#D1F0FF",
          "8": "#75AAAC"
        },
        green: {
          ...colors.green,
          "1": "#99DB98",
          "2": "#9ECA82",
          "3": "#A8D459",
          "4": "#8AB84B",
          "5": "#7A9B62",
          "6": "#8EBC53",
          "7": "#d2f8d2",
          "8": "#489091",
          "9": "#EEF7DE",
          "10": "#43AE8E",
          "11": "#458B8E",
        },
        orange: {
          ...colors.orange,
          "1": "#EBA289",
          "2": "#FF743A",
          "3": "#FFD324",
          "4": "#FB8B42",
          "5": "#FF741C",
          "6": "#EAC852",
          "7": "#FFE9DA",
          "8": "#FF7D36",
          "9": "#D96231",
          "10": "#FF8857",
          "11": "#E54C4B",
          "12": "#FF5B00",
          "13": "#F7562F",
          "13-2": "#FA8B42",
          "14": "#FFEEE3",
        },
        red: {
          ...colors.red,
          "1": "#EC5E5E",
          "2": "#E45050",
          "3": "#E36263",
          "4": "#E44E4E",
          "5": "#D45959",
          "6": "#F6532A",
          "7": "#E64F4C",
          "8": "#e7cfd7",
          "9": "#F7532B",
          "10": "#E84E47",
          "11": "#F86347",
          "12": "#FF5151",
          "13": "#D04727",
          "14": "#FF5959",
          "15": "#E34646",
          "16": "#AE4A43",
          "17": "#EA5252",
          "18": "#BB6963",
          "19": "#ff300c",
          "20": "#E2380F",
          "21": "#F32424",
          "22": "#FFE8E8",
          "23": "#FFE2E2",
          "24": "#FFDBDB",
          "25": "#ED6D6D",
          "26": "#EF7C7C",
          "27": "#DC726D",
        },
        black: {
          ...colors.black,
          "0": "#000000",
          "1": "#3D4353",
          "2": "#67728C",
          "3": "#85909B",
          "4": "#464B5B",
          "5": "#31414A",
          "6": "rgba(0,0,0,0.6)",
          "7": "#2F4252",
          "8": "#434858",
          "9": "#222222",
          "10": "#191919",
          "11": "#404656",
          "12": "#0F1722",
          "13": "#52525B",
          "14": "#3E3E41",
          "15": "#72707A",
          "16": "#54555C",
          "17": "#C6C6C6",
          "18": "#4A5060",
          "19": "#737782",
          "20": "#414143",
          "21": "rgba(0,0,0,0.04)",
          "22": "4A505F",
        },
        yellow: {
          ...colors.yellow,
          "1": "#E7B768",
        },
      },

      spacing: {
        "none": "0",
        "0": "0",
        "30": "7.5rem",
        "40": "10rem",
        "96": "24rem",
        "128": "32rem",
      },
      padding: {
        "none": "0",
        "0": "0",
        "1/2": "0.125rem",
        "9/2": "1.125rem",
        "15/16": "0.9375rem",
        "0.8945": "0.8945rem",
        "1": "0.25rem",
        "1.5": "0.375rem",
        "1.75": "0.4375rem",
        "2": "0.5rem",
        "2.5": "0.625rem",
        "3": "0.75rem",
        "3.5": "0.875rem",
        "4": "1rem",
        "4.5": "1.125rem",
        "4.625": "1.15625rem",
        "5": "1.25rem",
        "5.2": "1.3rem",
        "5.25": "1.3125rem",
        "5.5": "1.375rem",
        "6": "1.5rem",
        "6.25": "1.5625rem",
        "6.5": "1.625rem",
        "6.75": "1.6875rem",
        "7": "1.75rem",
        "7.5": "1.875rem",
        "8.5": "2.125rem",
        "9": "2.25rem",
        "10": "2.5rem",
        "11": "2.75rem",
        "12.5": "3.125rem",
        "13.037125": "3.25928125rem",
        "13.2": "3.3rem",
        "13.5": "3.375rem",
        "14": "3.5rem",
        "15": "3.75rem",
        "15.8125": "3.953125rem",
        "16.5": "4.125rem",
        "17.5": "4.375rem",
        "23": "5.75rem",
        "23.625": "5.90625rem",
        "28": "7rem",
        "32": "8rem",
        "36": "9rem",
        "44": "11rem",
        "25": "6.25rem",
        "1.563rem": "1.563rem",
        "18.752": "4.688rem",
      },
      margin: {
        "none": "0",
        "0": "0",
        "1/2": "0.125rem",
        "1.5": "0.375rem",
        "2": "0.5rem",
        "2.5": "0.625rem",
        "11/16": "0.6875rem",
        "3": "0.75rem",
        "3.5": "0.875rem",
        "4": "1rem",
        "5.5": "1.375rem",
        "6.75": "1.6875rem",
        "7": "1.75rem",
        "7.5": "1.875rem",
        "8": "2rem",
        "30/16": "1.875rem",
        "9": "2.25rem",
        "10/16": "0.625rem",
        "10": "0.625rem",
        "10o": "2.5rem",
        "11": "2.75rem",
        "40/16": "2.5rem",
        "12": "3rem",
        "14": "3.5rem",
        "15": "3.75rem",
        "18": "4.5rem",
        "27": "6.75rem",
        "31": "7.75rem",
        "40": "10rem",
        "50": "12.5rem",
        "62": "15.5rem",
        "84": "21rem",
      },
      maxWidth: {
        ...maxWidth,
        "1/2": "50%",
        "3": "0.75rem",
        "4": "1rem",
        "5": "1.25rem",
        "8": "2rem",
        "9": "2.25rem",
        "10": "2.5rem",
        "11": "2.75rem",
        "12": "3rem",
        "14": "3.5rem",
        "15": "3.75rem",
        "16": "4rem",
        "20": "5rem",
        "24": "6rem",
        "25": "6.25rem",
        "26": "6.5rem",
        "28": "7rem",
        "30": "7.5rem",
        "32": "8rem",
        "35": "8.75rem",
        "36": "9rem",
        "38": "9.5rem",
        "40": "10rem",
        "44": "11rem",
        "45": "11.25rem",
        "48": "12rem",
        "49": " 12.25rem",
        "50": "12.5rem",
        "52": "13rem",
        "56": "14rem",
        "58": "14.5rem",
        "60": "15rem",
        "61": "15.25rem",
        "62": "15.5rem",
        "64": "16rem",
        "66": "16.5rem",
        "68": "17rem",
        "72": "18rem",
        "74": "18.5rem",
        "75": "18.75rem",
        "76": "19rem",
        "80": "20rem",
        "84": "21rem",
        "85": "21.25rem",
        "87.5": "21.875rem",
        "88": "22rem",
        "90": "22.5rem",
        "92": "23rem",
        "94": "23.5rem",
        "96": "24rem",
        "105": "26.25rem",
        "107": "26.5rem",
        "100": "25rem",
        "112": "28rem",
        "116": "29rem",
        "120": "30rem",
        "125": "31.25rem",
        "130": "32.5rem",
        "136": "34rem",
        "140": "35rem",
        "146": "36.5rem",
        "155": "38.75rem",
        "160": "40rem",
        "176": "44rem",
        "228": "57rem",
        "230": "57.5rem",
        "240": "60rem",
        "234": "58.5rem",
        "950/16": "59.375rem",
        "288": "72rem",
        badge: "1.875rem",
        "employee-empty": "20.625rem",
        "employee-empty-select": "16.875rem",
        "70": "17.5rem",
        "250": "62.5rem",
      },
      minWidth: {
        "2.5": "0.625rem",
        "3.5": "0.875rem",
        "4": "1rem",
        "4.5": "1.125rem",
        "5": "1.25rem",
        "6": "1.5rem",
        "7": "1.75rem",
        "7.5": "1.875rem",
        "8": "2rem",
        "12": "3rem",
        "16": "4rem",
        "20": "5rem",
        "23": "5.75rem",
        "25": "6.25rem",
        "27.5": "6.875rem",
        "30": "7.5rem",
        "32": "8rem",
        "34": "8.5rem",
        "35": "8.75rem",
        "36": "9rem",
        "38": "9.5rem",
        "40": "10rem",
        "42": "10.5rem",
        "44": "11rem",
        "48": "12rem",
        "50": "12.5rem",
        "52.5": "13.125rem",
        "56": "14rem",
        "62": "15.5rem",
        "67": "16.75rem",
        "68": "17rem",
        "76": "19rem",
        "80": "20rem",
        "140": "35rem",
        "148": "37rem",
        "155": "38.75rem",
        "employee-empty": "20.625rem",
        "employee-empty-btn": "6.6875rem",
        "15/16": "0.9375rem",
        "1/2": "50%",
      },
      maxHeight: {
        "8": "2rem",
        "10": "2.5rem",
        "11": "2.75rem",
        "13.5": "3.375rem",
        "18": "4.5rem",
        "20": "5rem",
        "30": "7.5rem",
        "34": "8.5rem",
        "36": "9rem",
        "38": "9.5rem",
        "40": "10rem",
        "42": "10.5rem",
        "46": "11.5rem",
        "50": "12.5rem",
        "52": "13rem",
        "56": "14rem",
        "60": "15rem",
        "64": "16rem",
        "70": "17.5rem",
        "72": "18rem",
        "76": "18.75rem",
        "80": "20rem",
        "84": "21rem",
        "87.5": "21.875rem",
        "92": "23rem",
        "98": "24.5rem",
        "100": "25rem",
        "104": "26rem",
        "106": "26.5rem",
        "112": "28rem",
        "120": "30rem",
        "122": "30.25rem",
        "200": "50rem",
        "208": "52rem",
        "screen-60": "60vh",
        "screen-80": "80vh",
      },
      minHeight: {
        "10": "2.5rem",
        "11": "2.75rem",
        "32": "8rem",
        "40": "10rem",
        "50": "12.5rem",
        "52": "13rem",
        "64": "16rem",
        "72": "18rem",
        "76": "18.75rem",
        "80": "20rem",
        "92": "23rem",
        "100": "25rem",
        "112": "28rem",
        "125": "31.25rem",
        "70vh": "70vh",
        "75vh": "75vh",
        "80vh": "80vh",
      },
      borderColor: {
        ...borderColor,
        gray: {
          ...borderColor.gray,
          "1": "#d1d1d1",
          "2": "#979797",
          "3": "#D9D9D9",
          "4": "#e3e3e3",
          "5": "#C6C6C6",
          "6": "#e0e0e0",
          "7": "#D2D2D2",
        },
        red: {
          ...borderColor.red,
          "1": "#ED6D6D",
        }
      },
      textColor: {
        "main-blue": "#1084FF",
        "main-black": "#18181B",
      },
      textAlign: {
        ...textAlign,
        "webkit-center": "-webkit-center",
      },
      zIndex: {
        ...zIndex,
        "1": 1,
        100: 100,
      },
      cursor: {
        "context-menu": "context-menu",
      },
    },
  },
  variants: {
    opacity: ["hover", "focus", "disabled", "group-hover"],
    margin: ["first", "last", "responsive", "hover", "focus", "active"],
    padding: ["first", "last", "responsive", "hover", "focus", "active"],
    backgroundColor: [
      "responsive",
      "hover",
      "focus",
      "active",
      "disabled",
      "group-hover",
    ],
  },
  plugins: [transitionsPlugin()],
}
